/* eslint-disable max-lines */
import endsWith from "lodash.endswith";
import { CURRENCY_ID, INDIAN_RUPEE } from "../../constants/common/common";
import { cleverTapCommonWords } from "../../analytics/clevertapConstants";
import { productTypes } from "../../constants/pageConstants/pdpConstants";
import { GET_IS_SAME_DAY_CATALOG } from "../../api/common/constant";
import { fetchContentFromCMSOnClient } from "../../api/common";

/**
 * Description placeholder
 * @date 04/04/2024 - 15:18:39
 *
 * @export
 * @returns {*}
 */
export default function isMobile() {
  let isMobileDevice = false;
  if (typeof window !== "undefined") {
    isMobileDevice = window?.matchMedia("(max-width: 1024px)")?.matches;
  }
  return isMobileDevice;
}

/**
 * Get the initials from a given name.
 * @param {string} name - The name to get initials from.
 * @returns {string} - Initials of the name.
 */
export const getFirstLetters = (name) => {
  const words = name?.split(" ");
  let initials = "";
  words?.forEach((word) => {
    initials += word.charAt(0);
  });
  return initials;
};

/**
 * Throttle function to reduce the multiple api calls.
 * @param {*} func
 * @param {*} delay
 * @returns
 */
export function throttle(func, delay) {
  let timeout = null;
  return function (...args) {
    if (!timeout) {
      timeout = setTimeout(() => {
        func(...args);
        timeout = null;
      }, delay);
    }
  };
}

/**
 * Splits a string by commas and returns the part at the specified index.
 *
 * @param {string} value - The string to split.
 * @param {number} index - The index of the part to return.
 * @returns {string} - The part of the string at the specified index, or an empty string if the index is out of bounds.
 */
export const stringWithCommaSplitter = (value, index) => {
  if (value !== undefined) {
    const parts = value?.split(", ");
    if (index >= 0 && index < parts?.length) {
      return parts[index];
    } else {
      return value;
    }
  }
};

/** To check if passed string is a json string
 *
 * @param {string} str json string
 *
 * @returns {boolean} true or false depending upon if string can be parsed
 */
export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * This method provides a polyfill for the smooth scroll, This gets called only when the native smooth scroll is not
 * present in the Browser
 *
 * @param {*} to element offset from top.
 * @param {*} duration duration for scroll.
 */
export const smoothScrollPolyfill = (to, duration) => {
  const element = document.scrollingElement || document.documentElement;
  const start = element.scrollTop;
  const change = to - start;
  const startDate = +new Date();
  /**
   * This method calculates the time for ease in and out
   *
   * @param {number} t current time.
   * @param {number} b start value.
   * @param {number} c change in value.
   * @param {number} d duration.
   * @returns {number} ease in out quadrant.
   */
  const easeInOutQuad = (t, b, c, d) => {
    let time = t;
    time /= d / 2;
    if (time < 1) return (c / 2) * time * time + b;
    time -= 1;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  /**
   * This performs the animation to the scroll.
   */
  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = to;
    }
  };
  animateScroll();
};

/**
 * This method provides a scroll behavior on click to the section present in the Dome
 *
 * @param {string} scrollId - div Id
 * @param {number} offsetValue - offset value
 */
export const scrollToSection = (scrollId, offsetValue) => {
  const supportsNativeSmoothScroll = "scrollBehavior" in document.documentElement.style;
  if (document.getElementById(scrollId)) {
    const eleOffsetTop = document.getElementById(scrollId).offsetTop - offsetValue;
    if (supportsNativeSmoothScroll) {
      window.scroll({ top: eleOffsetTop, behavior: "instant" });
    }
  }
};

/**
 * This method formats a given number into a shortened form using "K"
 * for thousands, appending "Reviews" to the end of the result.
 *
 * @param {number} number - The number to format
 * @returns {string} - The formatted string in "K" format if the number is
 * greater than or equal to 1000, otherwise returns the number followed by "Reviews"
 */
export const formatToK = (number, isReviewTextHide) => {
  if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K";
  }
  return `${number?.toString()} ${isReviewTextHide ? "" : "Reviews"}`;
};

/**
 * Debounce function to delay the execution of a given function until after a specified wait time.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 * @returns {Function} - A debounced version of the given function.
 */
export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

/**
 * This function returns an array of large images for the slick image slider for Desktop Product  card
 *
 * @param {string} url contains product image url
 * @param {number} imageCount number of images of particular product
 * @returns {Array} returns an array of multiple images
 */
export const getProductLargeImgList = (url, imageCount) => {
  if (!url) return ["/assets/images/image_placeholder-bb.png"];
  if (!imageCount) return [`${url.replace("/m/", "/l/")}`];

  const productImages = [];
  for (let index = 1; index <= imageCount; index += 1) {
    let imageUrl = url.replace("/m/", "/l/").split(".jpg")[0];
    const hasExtra = endsWith(imageUrl, "_1");
    if (hasExtra) {
      imageUrl = imageUrl.slice(0, -2);
    }
    productImages.push(`${imageUrl}_${index}.jpg`);
  }

  return productImages;
};

/**
 * Function to filter out null, undefined, and empty string values from an object
 *
 * @param {object} obj contains object to be filtered
 * @returns {object} filtered object
 */
export const removeEmptyNullAndUndefinedFromObject = (obj) => {
  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined && value !== ""),
  );
};

/**
 * Convert the filter objects in to array.
 * @param {*} filterObj
 * @returns
 */
export const convertFilterObjToArray = (filterObj) => {
  const manipulatedArr = filterObj
    ? Object?.values(filterObj)?.reduce((acc, item) => {
        if (item) {
          return [...acc, item];
        }
        return acc;
      }, [])
    : [];

  return manipulatedArr;
};

/** */
export const updateCurrency = (selectedCurrency = {}, price) => {
  let updatedPrice = price;
  let currencySymbol = INDIAN_RUPEE;
  if (
    price &&
    selectedCurrency?.currency?.id &&
    selectedCurrency?.currency?.factor &&
    selectedCurrency?.currency?.id !== CURRENCY_ID
  ) {
    updatedPrice = (selectedCurrency?.currency?.factor * parseFloat(price)).toFixed(2);
    currencySymbol = selectedCurrency?.currency?.id;
  }
  return `${currencySymbol} ${updatedPrice}`;
};

/**
 * Parses a metadata string to extract title, description, keywords, canonical URL, Twitter meta tags, OG meta tags, and relAlt tags.
 *
 * @param {string} metaString- The raw metadata string.
 * @returns {Object} An object containing title, description, keywords, canonical URL, Twitter meta tags, OG meta tags, and relAlt tags.
 */
export const parseMetaString = (metaString) => {
  if (!metaString) {
    return {
      title: "",
      description: "",
      keywords: "",
      canonical: "",
      og: {
        title: "",
        description: "",
        image: "",
        url: "",
        type: "",
      },
      headAnalyticsData: {
        links: [],
        scriptSources: [],
        inLineScripts: [],
      },
    };
  }
  const titleMatch = metaString?.match(/<title>(.*?)<\/title>/);
  const descriptionMatch = metaString?.match(/<meta name="description" content="(.*?)"/);
  const keywordsMatch = metaString?.match(/<meta name="keywords" content="(.*?)"/);
  const canonicalMatch = metaString?.match(/<link rel="canonical" href="(.*?)"/);
  const ogTitleMatch = metaString?.match(/<meta property="og:title" content="(.*?)"/);
  const ogDescriptionMatch = metaString?.match(/<meta property="og:description" content="(.*?)"/);
  const ogImageMatch = metaString?.match(/<meta property="og:image" content="(.*?)"/);
  const ogUrlMatch = metaString?.match(/<meta property="og:url" content="(.*?)"/);
  const ogTypeMatch = metaString?.match(/<meta property="og:type" content="(.*?)"/);

  const scriptTags = metaString?.match(/<script[^>]*>.*?<\/script>/gs);
  const srcScripts = [];
  const inLineScripts = [];

  scriptTags?.forEach((scriptTag) => {
    // Look for src attributes strictly within the opening <script> tag
    const srcMatch = scriptTag.match(/<script[^>]*src=["']?([^"'>]+)["']?[^>]*>/);
    const hasAsync = /async/.test(scriptTag); // Check if async is present
    const hasDefer = /defer/.test(scriptTag); // Check if defer is present
    const typeMatch = scriptTag.match(/type=["'](.*?)["']/); // Look for type attribute
    const scriptType = typeMatch ? typeMatch[1] : "text/javascript";

    if (srcMatch) {
      // If a src attribute is found, it's an external script
      srcScripts.push({
        src: srcMatch[1],
        async: hasAsync,
        defer: hasDefer,
        type: scriptType,
      });
    } else {
      // If no src attribute, it's an inline script
      const inlineScript = scriptTag.replace(/<script[^>]*>|<\/script>/g, "").trim();

      // Ensure inline script is not empty or only whitespace
      if (inlineScript) {
        inLineScripts.push({
          content: inlineScript,
          type: scriptType,
        });
      }
    }
  });

  // Match all link tags with dns-prefetch, prefetch, preload, and preconnect
  const dnsPrefetchLinks = metaString?.match(/<link rel="(dns-prefetch|prefetch|preload|preconnect)" href="(.*?)"/g);

  // Map the matched links into the desired object format
  const links = dnsPrefetchLinks
    ? dnsPrefetchLinks.map((link) => {
        // Extract the rel and href attributes from the matched link string
        const relMatch = link.match(/rel="(.*?)"/);
        const hrefMatch = link.match(/href="(.*?)"/);

        return {
          rel: relMatch ? relMatch[1] : "",
          href: hrefMatch ? hrefMatch[1] : "",
        };
      })
    : [];

  return {
    title: titleMatch ? titleMatch[1] : "",
    description: descriptionMatch ? descriptionMatch[1] : "",
    keywords: keywordsMatch ? keywordsMatch[1] : "",
    canonical: canonicalMatch ? canonicalMatch[1] : "",
    og: {
      title: ogTitleMatch ? ogTitleMatch[1] : "",
      description: ogDescriptionMatch ? ogDescriptionMatch[1] : "",
      image: ogImageMatch ? ogImageMatch[1] : "",
      url: ogUrlMatch ? ogUrlMatch[1] : "",
      type: ogTypeMatch ? ogTypeMatch[1] : "",
    },
    headAnalyticsData: {
      links,
      scriptSources: srcScripts,
      inLineScripts,
    },
  };
};
/**
 * This method is used to check if cutoff timestamp exists
 *
 * @param {object} cutOffTimeStamp contains time.
 * @returns {boolean} returns true or false.
 */
export const isSameDayDeliveryAvailable = (cutOffTimeStamp) => {
  if (!cutOffTimeStamp) {
    return true;
  }
  const currentTime = new Date();
  const cuttoffSplit = cutOffTimeStamp && cutOffTimeStamp?.split(":");
  const cutoffTime = new Date();
  cutoffTime.setHours(cuttoffSplit[0]);
  cutoffTime.setMinutes(cuttoffSplit[1]);
  const isCutoffTimePassed = cutoffTime >= currentTime;
  return isCutoffTimePassed;
};

/**
 * Function to parse date from DD-MM-YYYY format
 * @param {*} dateString
 * @returns
 */
export function parseDate(dateString) {
  const [day, month, year] = dateString.split("-");
  return new Date(`${year}-${month}-${day}`);
}

/**
 * Retrieves the hash part of the current URL.
 *
 * @returns {string} The hash portion of the current URL (e.g., "#section1").
 *                   Returns an empty string if there is no hash or if executed in a non-browser environment.
 */
export const getHash = () => (typeof window !== "undefined" ? window.location.hash : "");

/**
 * Removes a specific hashtag from a URL.
 *
 * @param {string} url - The full URL containing the hashtags.
 * @param {string} hashtag - The specific hashtag to remove (e.g., "removeMe").
 * @returns {string} - The updated URL with the specified hashtag removed.
 * @throws {TypeError} - If the input URL is invalid.
 */
export function removeHashtagFromURL(hashtag) {
  const url = typeof window !== "undefined" ? window.location.href : "";
  const finalHash = hashtag.startsWith("#") ? hashtag.slice(1) : hashtag;
  try {
    const urlObj = new URL(url);
    const currentHash = urlObj.hash;

    if (currentHash.includes(finalHash)) {
      const updatedHash = currentHash
        .split("#") // Split the hash into parts
        .filter((tag) => tag !== finalHash) // Remove the specified hashtag
        .join("#"); // Join the remaining parts

      urlObj.hash = updatedHash ? `#${updatedHash}` : ""; // Update the hash
    }

    // Use history API to update the URL without reloading
    if (typeof window !== "undefined") {
      window.history.replaceState(null, "", urlObj.toString());
    }
  } catch (error) {
    console.error("Invalid URL:", error.message);
    return url;
  }
}

/**
 * method removes hash from the url
 *
 * @param {string} url - url string
 * @returns {string} final url
 */
export const removeHashFromURL = (url) => {
  const destinationURL = new URL(url);
  destinationURL.hash = "";
  return destinationURL.href;
};

/**
 * Triggers a click event on the button with the ID "bottom_drawer_btn".
 * This function is typically used to programmatically close a bottom drawer component.
 *
 * @function closeBottomDrawerFromParent
 */
export function closeBottomDrawerFromParent() {
  const closeBottomDrawer = document.getElementById("bottom_drawer_btn");
  if (closeBottomDrawer) {
    closeBottomDrawer.click();
  }
}

/**
 * Determines the page name based on certain conditions.
 *
 * @param {boolean} isHome - A flag indicating if the page is the home page.
 * @param {boolean} isPLP - A flag indicating if the page is a Product Listing Page (PLP).
 * @param {string} categoryId - The category ID to return if the page is a PLP.
 * @param {string} urlIdentifier - A URL identifier to return if the page is neither home nor PLP.
 *
 * @returns {string} The page name based on the input flags:
 * - Returns `cleverTapCommonWords.HOME` if `isHome` is true.
 * - Returns the `categoryId` if `isPLP` is true.
 * - Returns `urlIdentifier` if neither `isHome` nor `isPLP` are true.
 */
export const pageName = (isHome, isPLP, categoryId, urlIdentifier) => {
  if (isHome) {
    return cleverTapCommonWords.HOME;
  } else if (isPLP) {
    return categoryId;
  }
  return urlIdentifier;
};

/**
 * Toggles the visibility of an element by its ID.
 *
 * @param {string} id - The ID of the element to show or hide.
 * @param {boolean} isVisible - Whether to show (true) or hide (false) the element.
 */
export const toggleElementVisibility = (id, isVisible) => {
  const element = document.getElementById(id);
  if (!element) return;
  element.style.display = isVisible ? "block" : "none";
};

/**
 * This method initiates the lazy loading of all images on a page.
 * When the element is in the view port the data-src attributes or data-imageurl attributes
 * values are assigned to src attributes to load the images.
 */
export const lazyLoadImgs = () => {
  const elements = document.querySelectorAll("[data-imageurl],[data-src]");
  if (elements) {
    if ("IntersectionObserver" in window) {
      const imageObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            if (lazyImage?.dataset?.imageurl) {
              if (lazyImage?.src !== lazyImage.dataset.imageurl) {
                lazyImage.src = lazyImage.dataset.imageurl;
              }
            } else if (lazyImage?.dataset?.src) {
              if (lazyImage.src !== lazyImage.dataset.src) {
                lazyImage.src = lazyImage.dataset.src;
              }
            }
          }
        });
      });
      elements.forEach((v) => imageObserver.observe(v));
    } else {
      elements.forEach((elem) => {
        const element = elem;
        if (element?.dataset?.src) element.src = elem.dataset.src;
        else if (element?.dataset?.imageurl) element.src = elem.dataset.imageurl;
      });
    }
  }
};
/**
 * Determines the delivery date.
 *
 * @param {string} deliveryDates The dateString of the product.
 * @returns {Date} The delivery Date.
 */
export const processDeliveryDate = (deliveryDates) => {
  /**
   * if deliveryDates is not present then sending a empty string.
   */
  let dateObj = "";
  if (deliveryDates && typeof deliveryDates === "string") {
    let dateParts;
    if (deliveryDates.includes("-")) {
      dateParts = deliveryDates.split("-");
    } else if (deliveryDates.includes("/")) {
      dateParts = deliveryDates.split("/");
    } else {
      return dateObj;
    }
    if (dateParts.length === 3) {
      dateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
      return dateObj;
    }
    return dateObj;
  }
  return dateObj;
};
/**
 * This method is used to calculate percentage off product
 *
 * @param {number} listPrice list price.
 * @param {number} price price.
 * @returns {number} percentage
 */
export function calDiscountPercentage(listPrice, price) {
  const priceDifference = parseFloat(listPrice) - parseFloat(price);
  const percentage = (parseFloat(priceDifference) / parseFloat(listPrice)) * 100;
  return Math.floor(percentage);
}
/**
 * Determines the delivery type based on the primary category ID.
 *
 * @param {string} primaryCategoryId The primary category ID of the product.
 * @returns {string} The delivery type ('express' or 'courier').
 */
export const getProductDeliveryType = (primaryCategoryId) => {
  if (primaryCategoryId === productTypes.EXPRESS || primaryCategoryId === productTypes.PERSONALIZED) {
    return productTypes.EXPRESS;
  }
  return productTypes.COURIER;
};

/**
 * Smoothly scrolls the page to the element with the specified ID.
 *
 * @param {string} elementId - The ID of the target element to scroll to.
 * @throws {Error} Logs an error to the console if the element with the given ID is not found.
 *
 */
/**
 * Smoothly scrolls the page to the element with the specified ID, with an offset.
 *
 * @param {string} elementId - The ID of the target element to scroll to.
 * @param {number} [offset=0] - The offset to stop before the target element.
 */
export const onSmoothScrollToElement = (elementId, offset = 50) => {
  const targetElement = document.getElementById(elementId);
  if (targetElement) {
    // Calculate the position to scroll to with offset
    const offsetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;

    // Smooth scroll to the calculated position
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  } else {
    console.error(`Element with id "${elementId}" not found.`);
  }
};

/**
 * Remove parameters from url.
 * @param {*} url
 * @param {*} parameter
 * @returns
 */
export const removeURLParameter = (url, parameter) => {
  const destinationURL = new URL(url);
  destinationURL.searchParams.delete(parameter);
  return destinationURL.href;
};

/** */
export function getMonthNumber(monthName) {
  const date = new Date(`${monthName} 1, 2000`); // Create a date object with the given month name
  return date.getMonth() + 1; // getMonth() returns 0-based month, so add 1
}

/**
 * @param {string} str input string
 * @returns {string} string converted to title case
 */
export const toTitleCase = (str) => {
  return str?.toLowerCase()?.replace(/\b(\w)/g, (char) => char?.toUpperCase());
};

/**
 * this function fetch api data where sameday category is available
 */
export const fetchSameDayCatalog = async () => {
  try {
    const res = await fetchContentFromCMSOnClient(GET_IS_SAME_DAY_CATALOG());
    return res || [];
    // setSameDay(myRes);
  } catch (error) {
    console.error("Error fetching same-day catalog:", error);
  }
};

/**
 * Detects the operating system of the user's device based on the `navigator.userAgent` string.
 *
 * @returns {string} The name of the operating system. Possible values are:
 * - "Windows"
 * - "Windows Phone"
 * - "Android"
 * - "iOS"
 * - "MacOS"
 * - "Linux"
 * - "Unknown" (if the OS cannot be determined)
 */
export const getOperatingSystem = () => {
  if (typeof window === "undefined") {
    return "Unknown";
  }
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/win/i.test(userAgent)) {
    return "Windows";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  if (/mac/i.test(userAgent)) {
    return "MacOS";
  }
  if (/linux/i.test(userAgent)) {
    return "Linux";
  }
  return "Unknown";
};

/**
 * Detects the browser name based on the `navigator.userAgent` string.
 *
 * @returns {string} The name of the browser. Possible values are:
 * - "Chrome"
 * - "Firefox"
 * - "Safari"
 * - "Edge"
 * - "Opera"
 * - "Unknown" (if the browser cannot be determined)
 */
export const getBrowserName = () => {
  if (typeof window === "undefined") {
    return "Unknown";
  }
  const { userAgent } = window.navigator;

  if (/chrome|crios|crmo/i.test(userAgent) && !/edg/i.test(userAgent)) {
    return "Chrome";
  }
  if (/firefox|fxios/i.test(userAgent)) {
    return "Firefox";
  }
  if (/safari/i.test(userAgent) && !/chrome|crios|crmo|edg/i.test(userAgent)) {
    return "Safari";
  }
  if (/edg/i.test(userAgent)) {
    return "Edge";
  }
  if (/opera|opr\//i.test(userAgent)) {
    return "Opera";
  }
  return "Unknown";
};

/**
 * restrictAutoScrollingOnLoad this function restrict the browser to auto scroll where it left before load to that position
 */
export const restrictAutoScrollingOnLoad = () => {
  if (typeof window !== "undefined" && "scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }
};
