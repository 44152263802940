// import { getMock } from "../mock/mock-adaptor";
import { config } from "../utils/config";
import { HttpClient } from "./httpClient";
/**
 * Represents an API function class extending HttpClient for making HTTP requests.
 * @extends HttpClient
 */
export class InterceptorClient extends HttpClient {
  /**
   * The classInstance of the class.
   * @type {ApiFunction}
   */
  static classInstance;

  /**
   * The base URL for the API requests.
   * @type {string}
   */
  /**
   * Create a new classInstance of ApiFunction.
   */
  static baseUrl = "";

  isServer = typeof window === "undefined";

  /**
   *
   */
  constructor() {
    super();
  }

  /**
   * Get the singleton classInstance of ApiFunction.
   * @returns {ApiFunction} - The singleton classInstance.
   */
  static initAxiosClient = (baseUrl) => {
    InterceptorClient.baseUrl = baseUrl;
    if (!InterceptorClient.classInstance) {
      InterceptorClient.classInstance = new InterceptorClient(baseUrl);
    }
    return InterceptorClient.classInstance;
  };

  static getFTSInstance = (baseUrl) => {
    const url = baseUrl ? baseUrl : process.env.NEXT_PUBLIC_BASE_URL_API;
    return InterceptorClient.initAxiosClient(url);
  };

  static getFNTSInstance = (baseUrl) => {
    const url = baseUrl ? baseUrl : process.env.NEXT_PUBLIC_BASE_URL_API;
    return InterceptorClient.initAxiosClient(url);
  };

  static getGatewayInstance = (baseUrl) => {
    const url = baseUrl ? baseUrl : process.env.NEXT_PUBLIC_R2_CLIENT_API_GATEWAY;
    return InterceptorClient.initAxiosClient(url);
  };

  /**
   * Make a POST request.
   * @param {Object} data - The data to be sent with the request.
   * @param {string} uri - The URI of the endpoint.
   * @returns {Promise} - A Promise representing the result of the request.
   */
  post = async (data, uri, isMockApi, headers) => {
    let res = "";
    const isMock = config.isMockServerOn || isMockApi;
    res = isMock ? {} : this.instance.post(`${InterceptorClient.baseUrl}${uri}`, data, { headers });
    return res;
  };

  /**
   * Make a GET request.
   * @param {string} uri - The URI of the endpoint.
   * @returns {Promise} - A Promise representing the result of the request.
   */
  get = async (uri, isMockApi, params, headers) => {
    let res = "";
    const isMock = config.isMockServerOn || isMockApi;
    res = isMock
      ? {}
      : this.instance.get(`${InterceptorClient.baseUrl}${uri}`, {
          params,
          headers,
        });
    return res;
  };

  /**
   * Make a PATCH request.
   * @param {Object} data - The data to be sent with the request.
   * @param {string} uri - The URI of the endpoint.
   * @returns {Promise} - A Promise representing the result of the request.
   */
  update = async (data, uri) => {
    return this.instance(`${this.baseUrl}${uri}`, data);
  };

  /**
   * Make a PUT request.
   * @param {Object} data - The data to be sent with the request.
   * @param {string} uri - The URI of the endpoint.
   * @returns {Promise} - A Promise representing the result of the request.
   */
  put = async (data, uri) => {
    return this.instance.put(`${this.baseUrl}${uri}`, data);
  };

  /**
   * Make a DELETE request.
   * @param {string} uri - The URI of the endpoint.
   * @returns {Promise} - A Promise representing the result of the request.
   */
  delete = async (uri) => {
    return this.instance.delete(`${this.baseUrl}${uri}`);
  };
}
