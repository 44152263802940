export const CAKE_TYPE = {
  EGG_LESS: "EGGLESS",
  WITH_EGG: "WITH EGG",
};

export const constants = {
  DOMAIN_ID: "fnp.com",
  NoOfReviewRequest: 13,
  infoCircleGraySvg: "/icons/info-circle-gray.svg",
  ADD_TO_CART: "Add To Cart",
  ADD_TO_CART_ICON: "/icons/cart-olive-green.svg",
  BUY_NOW: "Buy Now",
  BUY_NOW_ICON: "/icons/cart-buy-now.svg",
  GO_TO_CART: "Go To Cart",
  SCREEN_SIZE: 1024,
  ANDROID: "Android",
  PRODUCT_DESCRIPTION: "Product Description",
  CARE_INSTRUCTIONS: "Care Instructions",
  DELIVERY_INFORMATION: "Delivery Information",
  ctId: "app_offers_module",
  SELECT_DELIVERY_DATE_AND_TIME: "Select Delivery Date & Time Slot",
  OFFERS_AVAILABLE: "Offers Available",
  ABOUT_THE_PRODUCT: "About the product",
  GIFT_RECEIVER_LOCATION: "Gift Receiver’s Location",
  COUNTRY_CHANGE: "Country change?",
  REDIRECTED_TO: "You will be redirected to",
  CATALOG: "catalog",
  CHANGE_PHOTO: "Change Photo",
  CHANGE_AUDIO: "Change Audio",
  UPLOAD_PHOTO: "Upload Photo",
  UPLOAD_AUDIO: "Upload Audio",
  UPLOAD_PHOTOS: "Upload Photos",
  SUCCESSFULLY_UPLOADED: "Successfully Uploaded",
  UPLOADING: "Uploading",
  WINDOW_REFRESH_MESSAGE: "Do not close the window or refresh.",
  SUCCESS: "success",
  INFO: "info",
  PERSONALIZED_HEADING: "Add Personalised Details",
  FILE_SIZE_TEXT: "File size should be between",
  FILE_SIZE: "File Size",
  FORMAT: "Format",
  ONLY: "Only",
  SMALLCASE_ONLY: "only",
  IMAGE: "IMAGE",
  TEXT: "TEXT",
  AUDIO: "AUDIO",
  INSTRUCTIONS: "Instructions",
  INSTRUCTIONS_LIST_LINE_ONE: "File size should be",
  INSTRUCTIONS_LIST_LINE_TWO: "Upload only",
  INSTRUCTIONS_LIST_LINE_THREE: "Please upload a good quality image.",
  INSTRUCTIONS_LIST_LINE_FOUR: "Please ensure you have rights to use the image.",
  DELETE: "Delete?",
  DELETE_MESSAGE: "Are you sure you want to delete this photo?",
  YES: "YES",
  NO: "NO",
  PLEASE_UPLOAD_ALL_PHOTOS_TO_CONTINUE: "Please upload all photos to continue.",
  PLEASE_CHECK_THE_INSTRUCTION_AGAIN: "Please check the instructions again",
  PHOTOS_UPLOADED_SUCCESSFULLY: "Successfully Uploaded!",
  MESSAGE_ON_CAKE: "Message On Cake",
  ADD_PERSONALISED_TEXT: "Add Personalised Text",
  CUSTOMIZE_PRODUCT: "Customize Product",
  SAVE_AND_CONTINUE: "Save & Continue",
  CONTINUE_WITH_DETAILS: "Please fill all details to continue.",
  SAVED_SUCCESSFULLY: "Saved successfully",
  AVAILABLE_COMBOS: "Available Combos",
  SMALLCASE_TEXT: "Text",
  ENTERED_TEXT: "Entered text",
  UPLOAD_PERSONALIZE_IMAGE: "Upload Personalize Image",
  SEO_KEYWORD: "Gift, Ferns N Petals, FNP",
  NOTIFICATION_FOR_CART: "Gift has been added to your cart.",
  VIEW_CART: "View Cart",
  PROCEED_TO_CHECKOUT: "Proceed To Checkout",
  PRODUCT_CANNOT_DELIVERED: "This product can not be delivered at the desired location",
  VIEW_AVAILABLE_GIFTS: "View Available Gifts",
  REDIRECT_TO_GIFTS_LP: "/all-gifts-lp",
  AVAILABLE_OPTIONS: "Available options",
  COURIER_DELIVERY: "Courier Delivery",
  COURIER_NOT_AVAILABLE: "Courier delivery is not available at entered location",
  HAND_NOT_AVAILABLE: "Hand delivery is not available at entered location",
  FAST_DELIVERY_AVAILABLE: "Faster delivery options available",
  NOT_AVAILABLE: "Not Available",
  EARLIEST_BY: "Earliest by",
  OUT_OF_STOCK_PDP: "Out Of Stock",
  UPLOAD_CUSTOMIZE_PHOTOS: "Upload Photos & Customize",
  CHANGE_PHOTOS: "Change Photos",
  ENTER_TEXT: "Enter Text",
  CONTINUE: "Continue",
  MODIFY_YOUR_ORDER: "Modify Your Order",
  SELECT_FLAVOR: "Select Flavour",
  DELIVERY_DATE_AND_TIME_SLOT_VALIDATION: "Please Select Delivery Date & Time Slot",
  CHANGE_PHOTO_AND_CUSTOMIZE: "Change Photo & Customize",
  UPLOAD_PHOTO_AND_CUSTOMIZE: "Upload Photo & Customize",
  THIS_IS_REQUIRED: "This is required",
  TAP_TO_ZOOM: "Tap to zoom",
  TIME_LEFT_TO_GET_DELIVERED: "Time left to get delivered today",
  ROLL_OVER_IMAGE: "Roll over image to zoom in",
  CLICK_TO_OPEN_EXPANDED_VIEW: "Click to Open expanded view",
  SURGE_PRICING: "Surge Pricing",
  DELIVERY_NOT_AVAILABLE: "Delivery is not available on this date.",
  TERMS_N_CONDITIONS: "Terms & Conditions",
};

export const productTypes = {
  COURIER: "COURIER",
  EXPRESS: "EXPRESS",
  CAKE: "CAKE",
  INTERNATIONAL: "INTERNATIONAL",
  PERSONALIZED: "PERSONALIZED",
  DIGITAL_GOOD: "DIGITAL_GOOD",
  FINISHED_GOOD: "FINISHED_GOOD",
};

export const labelStyle = "text-16 md:text-14 lg:text-16 font-600 pb-12 leading-18";

export const toastStyles =
  "fixed left-1/2 z-20 flex w-full max-w-md -translate-x-1/2 transform items-center justify-center";

/**
 * UPLOAD_PHOTOS Constant
 * @param {*} maxInputRequired
 * @returns
 */
export const UPLOAD_PHOTOS = (maxInputRequired) => {
  return `Upload ${maxInputRequired} Photos`;
};

export const metrics = [
  {
    icon: "/icons/year-of-trust.svg",
    value: "25+",
    description: "Years of Trust and Service",
  },
  {
    icon: "/icons/smile.svg",
    value: "108K+",
    description: "Happy customers served",
  },
  {
    icon: "/icons/world.svg",
    value: "30+",
    description: "Countries has branches",
  },
];

/**
 * serenadeProductNotification
 * @param {*} multiOrderProductDetails
 * @returns
 */
export const serenadeProductNotification = (multiOrderProductDetails) => {
  return `The date you’re selecting would be the start date of your service and your product would be delivered for the next ${multiOrderProductDetails?.noOfDays} days.`;
};

export const CTapCampaignConstants = {
  PDP_FREE_GIFT_TOPIC_NAME: "free-gift-tag",
};

export const PRODUCT_VIDEO_POSITION = 1;
